import React, { IframeHTMLAttributes } from 'react';

type Props = IframeHTMLAttributes<HTMLIFrameElement> & {
  title?: string;
};

function IFrame({ title = 'YouTube Video Player', className, ...props }: Props) {
  const innerIframeStyle: any = {
    height: '500px',
  };

  return (
    <iframe
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      className={`${className} iframe`}
      allowFullScreen
      {...props}
      title={title}
      style={innerIframeStyle}
    ></iframe>
  );
}

export default IFrame;
